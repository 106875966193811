import { Html } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import { levaBehindTheScenes } from '../../LevaDebug/LevaBehindTheScenes'
import './behind-the-scenes.scss';
import { useZStore } from '../../../../../store/store';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';


export default function BehindTheScenes() {
	BehindTheScenes.defaultProps = {
		position:
		{
			value: [-0.8057, 1.25, -0.6255],
			step: 0.01,
		},
	}
	const historyProps = { ...BehindTheScenes.defaultProps };

	const { position } = levaBehindTheScenes(historyProps)

	const updateShowModal = useZStore(state => state.updateShowModal)
	const updateModalContent = useZStore(state => state.updateModalContent)


	const handleInfoClick = () => {
		updateModalContent('Info')
		updateShowModal(true)
	}

	return (
		<>
			<Html
				position={position}
				rotation={[0.02, -0.45, 0]}
				transform
				zIndexRange={[1111, 0]}
				distanceFactor={0.1}
			>
				<div style={{ fontSize: '24px' }} className='history'>
					<ul className={'history-list'} style={{ opacity: 0 }}>
						<li onClick={() => handleInfoClick()} style={{ padding: 0, justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '6px' }}>
							<PlayCircleFilledIcon style={{ fontSize: '5rem' }} />
						</li>
					</ul>
				</div>
			</Html>
		</>
	)
}
