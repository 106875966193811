import { useRef, useMemo } from "react";
import Sizes from "../../hooks/Sizes";
import * as THREE from 'three'
import { useThree } from "@react-three/fiber";
import { MelinasDreamText } from "./MelinasDreamExperience/World/MelinasDreamText/MelinasDreamText";
import { OdysseyPresents } from "./MelinasDreamExperience/World/OdysseyPresents/OdysseyPresents";
import { Html } from "@react-three/drei";

const Intro = (props) => {
	const sizes = new Sizes();
	const { camera } = useThree()

	// Create and store references to the Vector3 instances
	const unitVector = useRef(new THREE.Vector3(0, 0, 0));
	const distanceVector = useRef(new THREE.Vector3(0, 0, 0));
	const objectVector = useRef(new THREE.Vector3(0, 0, 0));
	const htmlPositionRef = useRef(new THREE.Object3D(0, 0, 0));
	const cameraTarget = useRef(new THREE.Vector3([0, 0, 0]));

	var introDivWrapperStyle = {
		background: 'black',
		width: sizes.width,
		// height: sizes.height,
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}

	// Memoize the calculateHtmlPosition function
	const calculateHtmlPosition = useMemo(() => {
		return () => {
			const widthHalf = sizes.width / 2
			const heightHalf = sizes.height / 2
			distanceVector.current.copy(camera.position).sub(cameraTarget.current);
			unitVector.current.copy(distanceVector.current).divideScalar(distanceVector.current.length());
			htmlPositionRef.current.position.copy(camera.position).sub(unitVector.current);
			objectVector.current.setFromMatrixPosition(htmlPositionRef.current.matrixWorld);
			return [objectVector.current.x * widthHalf + widthHalf, -(objectVector.current.y * heightHalf) + heightHalf];
		};
	}, [sizes.width, sizes.height, camera.position, cameraTarget.current]);

	return <>
		<Html
			className={'intro-wrapper'}
			fullscreen
			style={{ color: 'transparent' }}
			calculatePosition={calculateHtmlPosition}
		>
			<div
				className={'introDivWrapper'}
				style={{ ...introDivWrapperStyle }}
			>
				{/* <OdysseyPresents /> */}
				{/* <MelinasDreamText /> */}
			</div>
		</Html>
	</>
}

export { Intro }



