import { LevaCamera } from "../MelinasDreamExperience/LevaDebug/LevaCamera";
import Sizes from "../../../hooks/Sizes";
import gsap from "gsap";
import { PerspectiveCamera } from "@react-three/drei";
import { forwardRef, useEffect, useLayoutEffect } from "react";
import { useRef } from "react";
import { useZStore } from "../../../store/store";
import useAudio from "../../../store/useAudio";
import { Sphere } from "@react-three/drei";


const Camera = forwardRef((props, ref) => {
	const updateCameraFlyInTimeline = useZStore(state => state.updateCameraFlyInTimeline);
	const setShowSectionArrows = useZStore(state => state.setShowSectionArrows);
	const updateSection = useZStore(state => state.updateSection);
	// const { playAudio } = useAudio();
	const widthBreakpoint = 700; // breakpoint for mobile vs desktop view

	useLayoutEffect(() => {
		// console.log("layout")

		const sizes = new Sizes();
		const zPosition = sizes.width < widthBreakpoint ? 0.55 : 0.12
		const timeline = gsap.timeline().pause();

		timeline.add(gsap.to(
			ref.current.position,
			{
				duration: 2,
				x: -0.018,
				y: 1.33,
				z: zPosition,
				ease: 'power4.InOut',
				onStart: () => {

				},
				onComplete: () => {
					ref.current.lookAt(cameraTarget.current.position)
					updateSection('home')
				}
			}
		));

		updateCameraFlyInTimeline(timeline);
	}, [])


	const sectionCoords = {
		info: {
			x: -0.97,
			y: 1.25,
			z: -0.35
		},
		home: {
			x: -0.018,
			y: 1.33,
			z: 0.12
		},
		history: {
			x: 0.72,
			// y: 1.28,
			y: 1.22,
			z: -0.27
		}
	}
	const sectionTargets = {
		info: {
			x: -0.8,
			y: 1.26,
			z: -0.63
		},
		home: {
			x: -0.018,
			y: 1.33,
			z: -0.357
		},
		history: {
			x: 0.72,
			y: 1.25,
			z: -0.57
		}
	}

	useEffect(() => {
		const fadeOutMakeUnclickable = (el) => {
			gsap.to(el, {
				opacity: 0,
				duration: 1,
			})
		}

		const fadeInMakeClickable = (el) => {
			gsap.to(el, {
				opacity: 1,
				duration: 1
			})
		}

		const unsubscribeIsAudioMuted = useZStore.subscribe(
			(state) => state.section,
			(value) => {
				if (value == 'home') {
					fadeOutMakeUnclickable('.history-list')
					fadeInMakeClickable('.watch-trailer-text')
					fadeInMakeClickable('.melinas-dream-title')

					gsap.to(ref.current.position,
						{
							duration: 1.5,
							...sectionCoords[value],
							ease: 'power2.inOut',
						})
					gsap.to(cameraTarget.current.position,
						{
							duration: 1.5,
							...sectionTargets[value],
							ease: 'power2.inOut',
							onUpdate: () => {
								ref.current.lookAt(cameraTarget.current.position)
							}
						})
				}
				if (value == 'info') {
					fadeOutMakeUnclickable('.watch-trailer-text')
					fadeOutMakeUnclickable('.melinas-dream-title')
					fadeInMakeClickable('.history-list')

					gsap.to(ref.current.position,
						{
							duration: 1.5,
							...sectionCoords[value],
							ease: 'power2.inOut',
						})
					gsap.to(cameraTarget.current.position,
						{
							duration: 1.5,
							...sectionTargets[value],
							ease: 'power2.inOut',
							onUpdate: () => {
								ref.current.lookAt(cameraTarget.current.position)
							}
						})
				}
				if (value == 'history') {
					fadeOutMakeUnclickable('.watch-trailer-text')
					fadeOutMakeUnclickable('.melinas-dream-title')
					fadeInMakeClickable('.history-list')

					gsap.to(ref.current.position,
						{
							duration: 1.5,
							...sectionCoords[value],
							ease: 'power2.inOut',

						})
					gsap.to(cameraTarget.current.position,
						{
							duration: 1.5,
							...sectionTargets[value],
							ease: 'power2.inOut',
							onUpdate: () => {
								ref.current.lookAt(cameraTarget.current.position)
							}
						})
				}


			}
		)
		return () => {
			unsubscribeIsAudioMuted()
		}
	}, [])

	const sizes = new Sizes();

	let currentSizeState = 'normal'

	if (sizes.width < widthBreakpoint) {
		currentSizeState = 'small'
	}

	sizes.on('resize', () => {
		if (sizes.width < widthBreakpoint) {
			if (currentSizeState == 'normal') {
				currentSizeState = 'small'
				gsap.timeline().to(
					ref.current.position,
					{
						duration: 1.5,
						x: -0.018,
						y: 1.33,
						z: 0.55,
						ease: 'power1.inOut',
					}
				);

			}
		}
		if (sizes.width >= widthBreakpoint) {
			if (currentSizeState == 'small') {
				currentSizeState = 'normal'
				gsap.timeline().to(
					ref.current.position,
					{
						duration: 1.5,
						x: -0.018,
						y: 1.33,
						z: 0.12,
						ease: 'power1.inOut',
					}
				);
			}
		}
	})

	Camera.defaultProps = {
		position: [0, 0, 0],
		fov: 75,
		near: 0.1,
		far: 1000
	}

	const cameraTarget = useRef();

	const { position, near, far } = LevaCamera(props)

	useLayoutEffect(() => {
		ref.current.lookAt(-0.018, 1.33, -0.357)
	}, [])

	return <>
		<PerspectiveCamera
			makeDefault
			ref={ref}
			position={position}
			aspect={sizes.width / sizes.height}
			near={near}
			far={far}
			onUpdate={self => self.updateProjectionMatrix()}
		>
			<object3D ref={cameraTarget} position={[-0.018, 1.33, -0.357]} />
		</PerspectiveCamera>
		{/* <mesh position={[-0.8, 1.25, -0.63]}>
			<sphereGeometry args={[0.01, 16, 14]} />
			<meshBasicMaterial color={'red'} />
		</mesh> */}
	</>
})

export { Camera }
