
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { CloseSVG } from '../Svgs/Close';
import './video-playback.scss';
import { useZStore } from "../../store/store";
import { getVideoSource } from "../../util/azure-video";


function VideoPlayback() {
	const isMobile = useZStore(state => state.isMobile);

	const updateFrameloop = useZStore(state => state.updateFrameloop)
	const updateIsVideoPlayback = useZStore(state => state.updateIsVideoPlayback)
	const updateIsLeavingVideoPlayback = useZStore(state => state.updateIsLeavingVideoPlayback)
	const isVideoPlayback = useZStore(state => state.isVideoPlayback)
	const isLeavingVideoPlayback = useZStore(state => state.isLeavingVideoPlayback)
	const videoSource = getVideoSource('prototype_trailer_720p.mp4');

	const componentRef = useRef(null);
	const videoRef = useRef(null);

	const onClose = () => {
		updateFrameloop('always')
		updateIsLeavingVideoPlayback(true);

		gsap.timeline().to(componentRef.current, { autoAlpha: 0, duration: 2, })
			.then(() => {
				updateIsVideoPlayback(false);
				updateIsLeavingVideoPlayback(false);
			});
	}

	useEffect(() => {
		if (isVideoPlayback) {
			videoRef.current.addEventListener('ended', () => setTimeout(() => onClose(), 300));

			gsap.timeline()
				.set(componentRef.current, { display: 'inherit' })
				.to(componentRef.current, { delay: isMobile ? 1.7 : 0.5, autoAlpha: 1, duration: 2 })
		}
	}, [isVideoPlayback]);

	// useEffect(() => {
	// 	if (videoRef?.current) {
	// 	}
	// }, [videoRef.current]);

	return (
		<div ref={componentRef} className='video-wrapper' style={{ opacity: 0, display: 'none' }}>
			<div className='close-icon' onClick={onClose} >
				<CloseSVG style={{ zIndex: 99999 }} />
			</div>

			{
				isVideoPlayback || isLeavingVideoPlayback ?
					<video ref={videoRef} className='full-video' controls src={videoSource} type="video/mp4" />
					: <></>
			}
		</div>
	);
}

export { VideoPlayback }