import { Html } from "@react-three/drei"
import { useRef } from "react"
import './ui-overlay.scss'

export function MainPageTitleMobile(props) {
	const mainPageTitleMobileRef = useRef()

	// top left text stuff
	const textArrTmpTop = "A Spartan Dream".toUpperCase().split(' ');
	const textArrDesc = "This is where the intro statement goes"
	const textCharArrTop = [];


	for (let i = 0; i < textArrTmpTop.length; i++) {
		textCharArrTop.push(textArrTmpTop[i].split(''));
	}

	return <>
		<div ref={mainPageTitleMobileRef}
			className={'main-page-title-html-content-wrapper'}
			onClick={props.playback}
			style={{
				opacity: 1,
				zIndex: 999
			}}
		>
			<div>
				<div className={'melinas-dream-title'} style={{ width: '75%' }}>
					{/* {
						textCharArrTop.map((word, i) => <div key={`${i}`} className={`melinas__word`}>
							{
								textCharArrTop[i].map((char, j) => <span key={`${j}`} className={`melinas__word__char`} style={{ fontSize: '2.6rem', opacity: 0 }}>
									{
										char == "&nbsp;" ? <span>&nbsp;</span> : char
									}
								</span>)
							}
						</div>)
					} */}
				</div>
			</div>
		</div>
	</>
}