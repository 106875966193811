import React, { useState, useRef, useEffect, Suspense, useMemo, useCallback } from "react";
import { useProgress } from "@react-three/drei";
import gsap from "gsap";
import "./enter.scss";
import { useZStore } from "../../../store/store";


export const Enter = React.forwardRef(({ children }, ref) => {
	const [isVisible, setIsVisible] = useState(true);
	const [firedAlready, setFiredAlready] = useState(false);
	const [betterProgress, setBetterProgress] = useState(0);
	const cameraFlyInTimeline = useZStore(state => state.cameraFlyInTimeline);
	const updateShowUI = useZStore(state => state.updateShowUI);
	const updateFrameloop = useZStore(state => state.updateFrameloop);

	const { active, progress, errors, item, loaded, total } = useProgress();
	const fullRef = useRef();
	const loadingTextRef = useRef();
	const introWrapperRef = useRef(null);
	introWrapperRef.current = document.querySelector(".intro-wrapper");

	// TERRIBLE PRACTICE
	const numFiles = 66;

	useEffect(() => {
		if (firedAlready && introWrapperRef.current) {
			updateFrameloop("always");
			cameraFlyInTimeline.play().then(() => {
				gsap.to(introWrapperRef.current, {
					autoAlpha: 0,
					duration: 1,
					onComplete: () => {
						setIsVisible(false);
					}
				});
				document.getElementById("three-fiber-canvas").style.zIndex = 1;
				updateShowUI(true);
			});
		}
	}, [firedAlready, cameraFlyInTimeline, updateFrameloop, updateShowUI]);

	useEffect(() => {
		setBetterProgress(Math.min((loaded / numFiles) * 100), 100);

		if (betterProgress >= 100) {
			enterExperienceWithCallback();
		}
	}, [progress, betterProgress]);

	const enterExperienceWithCallback = () => {
		if (!firedAlready) {
			setFiredAlready(true);
			gsap.to(fullRef.current, {
				autoAlpha: 0,
				duration: 1,
				ease: "Power4.InOut",
				onComplete: () => {
					setIsVisible(false);
				}
			});
		}
	};

	return (
		<>
			<Suspense>
				{children}
			</Suspense>

			{isVisible && (
				<div ref={fullRef} className={`full ${firedAlready && "clicked"}`}>
					<img src="/assets/images/LogoFullBlackBackground.jpeg" alt="Enter" style={{ width: '300px' }} />
					<div className="enter-button">
						<div className="enter-button__text">
							<div ref={loadingTextRef} className="text-loading">
								<h4>Loading</h4>
							</div>
						</div>
					</div>

					<div className="enter-progress">
						{Math.min(betterProgress.toFixed(), 100)} %
					</div>
				</div>
			)}

		</>
	);
});

export const animateTextTop = () => { };
