import React, { useRef, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { TextureLoader } from 'three';

export const BoxGroup = () => {
    const groupRef = useRef();
    const { camera, size } = useThree();

    useEffect(() => {
        if (groupRef.current) {
            const screenSpaceCoordinates = groupRef.current.children.map(mesh => {
                const vector = mesh.position.clone().project(camera);
                return {
                    x: Math.round(((vector.x + 1) / 2) * size.width),
                    y: Math.round(((-vector.y + 1) / 2) * size.height),
                };
            });

            // console.log(screenSpaceCoordinates);
        }
    }, [camera, size]);

    return (
        <>
            <group ref={groupRef} position={[-0.135, 1.5, -0.375]}>
                {/* <mesh>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="red" />
                </mesh>
                <mesh position={[0.2375, 0, 0]}>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="blue" />
                </mesh>
                <mesh position={[0, -0.055, 0]}>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="green" />
                </mesh>
                <mesh position={[0.2375, -0.055, 0]}>
                    <boxGeometry args={[0.0125, 0.0125, 0.0125]} />
                    <meshStandardMaterial color="yellow" />
                </mesh> */}
                <mesh position={[0.1165, -0.029, 0.02]} >
                    <planeGeometry args={[0.2415, 0.0755]} />
                    <meshPhysicalMaterial transparent alphaTest={0.8} >
                        <primitive attach="map" object={new TextureLoader().load("/assets/images/spartanDreamText.png")} />
                    </meshPhysicalMaterial>
                </mesh>
            </group>
        </>
    );

};
