import { BlobServiceClient } from "@azure/storage-blob";

export const getVideoSource = (video) => {
	const account = 'melinasdreamvideostorage';
	const sas = '?sp=r&st=2023-01-07T17:44:31Z&se=2024-01-02T01:44:31Z&spr=https&sv=2021-06-08&sr=c&sig=kIXuRU9t4qOJuDFtlbvZJ3%2B54DF6t30GTvfUit6h68g%3D';
	const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);

	return blobServiceClient.getContainerClient('melinasdreamvideos').getBlockBlobClient(video).url;
}

// const sas = '?sp=r&st=2023-03-10T00:24:06Z&se=2026-07-31T07:24:06Z&sv=2021-12-02&sr=c&sig=AZDCBhYX2DyBFcM0Am6fXbuOQtEamZZEgKwzCCmsUps%3D';
