import { levaDirectionalLight } from "../../LevaDebug/LevaDirectionalLight"
import { useRef, useEffect, useLayoutEffect } from "react"
import { useHelper, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { useControls } from "leva";
import { SpotLightHelper } from "three";
import { useFrame, useThree } from "@react-three/fiber";
import { scenes } from "../../../../../assets/data.json";
import spartanDreamPoster from '../../../../../assets/images/spartanDreamPoster.png'

export default function VideoSpotlight(props) {
    const { scene } = useThree()
    const projectorPlane = useGLTF('./assets/models/videoSpotlight/projectorPlane2.glb')

    const { position, rotation, intensity, distance, angle, penumbra, decay, focus } = useControls('Video Spotlight', {
        position: {
            value: { x: -0.02, y: 1.34, z: -0.21 }
        },
        rotation: {
            value: { x: 0, y: 0, z: 0 }
        },
        distance: {
            value: 5,
            step: 0.01
        },
        intensity: {
            value: 5,
            step: 0.1
        },
        angle: {
            value: 0.35,
            step: 0.01
        },
        penumbra: {
            value: 0,
            step: 0.1
        },
        decay: {
            value: 2,
            step: 0.1
        },
        focus: {
            value: 1,
            step: 0.01
        },
        fov: {
            value: 30,
            step: 1
        }
    }, { collapsed: true })
    const { projectoPos } = useControls('projectorPlane', {
        projectoPos: {
            value: { x: -0.02, y: 1.34, z: -0.22 },
            step: 0.001
        },
    }, { collapsed: true })

    useLayoutEffect(() => {
        const spotLightTarget = new THREE.Object3D()
        spotLightTarget.position.set(-0.02, 1.34, -0.357)
        scene.add(spotLightTarget)

        const videoSpotLight = new THREE.SpotLight()
        videoSpotLight.position.set(position.x, position.y, position.z)
        videoSpotLight.target = spotLightTarget
        videoSpotLight.angle = 0.8
        videoSpotLight.distance = 2
        videoSpotLight.decay = decay
        videoSpotLight.penumbra = 1
        // videoSpotLight.intensity = 1.5
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load('../../../../../assets/images/spartanDreamPoster.png', function (texture) {
            videoSpotLight.map = texture;
        }, undefined, function (error) {
            console.error('An error occurred loading the texture:', error);
        });
        videoSpotLight.castShadow = true
        videoSpotLight.shadow.mapSize.width = 1024
        videoSpotLight.shadow.mapSize.height = 1024
        videoSpotLight.shadow.camera.near = 0.01
        videoSpotLight.shadow.camera.far = 10
        videoSpotLight.shadow.camera.fov = 45
        videoSpotLight.shadow.focus = 0.5
        videoSpotLight.shadow.normalBias = 0.01
        projectorPlane.scene.traverse((obj) => {
            if (obj.isMesh) {
                obj.receiveShadow = true
                obj.castShadow = true
                obj.material = new THREE.MeshLambertMaterial({
                    color: '#ffffff',
                    side: THREE.DoubleSide,
                    transparent: true,
                    opacity: 0
                })
            }
        })

        scene.add(videoSpotLight)
    }, [])

    return <>
        <primitive object={projectorPlane.scene}
            position={[projectoPos.x, projectoPos.y, projectoPos.z]}
            scale={[0.001, 0.001, 0.001]}
            rotation={[Math.PI / 2, 0, 0]}
            castShadow
            receiveShadow
        />
    </>
}